import React from 'react'
import Cards from './Cards'
import FeaturedInventory from './FeaturedInventory'



const InventorySearch = () => {

  
      return (
         <div className='mt-0 '>
                  <div className='flex justify-center inset-0 text-lg px-4 text-center 
                           md:text-2xl   items-centerfont-bold text-white
                           py-4   bg-black 2x'>    
                        <h1 className=' '>
                           asdfasdf
                       </h1>
                  </div>

            <div 
                  className='text-white ' >
            
          <div className='flex flex-col bg-red-700 h-40 md:h-28 md:flex-row md:pb-7 md:items-center 
                           md:justify-between md:content-center  '>
                <h1 className='text-lg px-5 pt-3 font-bold lg:text-xl lg:pt-10 2xl:text-3xl 2xl:pt-8 text-center '> </h1>

                {/* search part */}
             <div className='border  mt-6 p-3  mx-5 flex relative lg:mt-12  md:w-80 md:mb-3 md:mr-12 lg:mr-16 xl:mr-36 '>
                <label className='flex items-center content-center '>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" 
                      className="h-5 w-5"> <path fillRule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                         clipRule="evenodd" />
                      </svg>

                    <input type='search' id='search' placeholder='Inventory Search' 
                           className='bg-transparent ps-3 focus:outline-none w-fit'/>  
                    
                    <button className='absolute inset-y-0 right-0 bg-[#10ACF5] py-2 px-4 rounded-l-lg'>
                        Search
                    </button>
                       
                </label>
             </div>   
          </div>
         < Cards /> 
          <FeaturedInventory />
          <div className='bg-gray-400 bg-opacity-10 text-black md:pt-5'>
               <h1 className='text-center font-extrabold text-2xl md:text-3xl md:mb-4'>WELCOME TO KOMMANDER INDUSTRIES</h1>
          

            <div className='px-6 md:px-7 lg:grid-cols-2 lg:grid grid gap-10  mx-auto container xl:gap-32'>
                  <div className='text-justify'>
                  <p className='text-lg md:text-xl lg:text-base flow-root my-1 leading-relaxed'>
                     We are a dedicated Yamaha WaveRunner dealer in Phoenix, Arizona.  In fact, we are the No. 1 Yamaha WaveRunner dealer in Arizona for both sales and customer service for 6 years running! Shop our
                     <a href='#/' className='text-red-600'> WaveRunners </a>in stock and the latest WaveRunner models, including FX, VX and SuperJet watercraft, along with our very own world renowned Kommander GP1 
                     race skis. At Kommander Industries, our professional sales team is ready to help you select the right WaveRunner or Kommander GP1 for you and your family, and our talented service department will 
                     make sure your WaveRunner ownership experience the best that it can be. We sell parts and accessories, too, including – of course – Yamaha WaveRunner parts, and we can get your PWC ready to race 
                     with our Kommander GP1 hull.</p>
                  <p className='text-lg flow-root md:text-xl lg:text-base my-4 leading-relaxed'>Though Kommander Industries specializes in Yamaha, we offer performance products and service for all brands of personal watercraft. We also sell 
                  Zieman boat trailers.</p>
                  <p className='text-lg flow-root md:text-xl lg:text-base leading-relaxed'>Founded in 2008, Kommander Industries' expertise and commitment to its customers is unrivaled in the Phoenix area, including Scottsdale, Mesa, Tucson 
                  and beyond. We operate with one goal: to exceed your expectations every day!</p>
                  </div>
               <div className='flex md:mt-3'>
                     <div className='w-full aspect-w-16 aspect-h-9'>
                     <iframe 
                           title='hero unique'
                           src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FKommanderInd&tabs=timeline&width=500&height=400&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" 
                           className='w-full h-96 lg:w-full'
                           style={{border: 'none', 
                           overflow: 'hidden'}}
                           scrolling="no" 
                           frameBorder="0" 
                           allowFullScreen={true}
                           allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
                     </iframe>
                  </div>
               </div>
            </div>  


            <div className='flex my-10 justify-center '>
                        <img 
                           src='https://res.cloudinary.com/doehyebmw/image/upload/v1699844834/Kommander/Logo/yamaha_logo_akbpch.jpg' 
                           alt='yamaha'
                           className='h-[8rem] w-[20rem] md:h-[10rem] md:w-[28rem] lg:h-[12rem] lg:w-[40rem]' 
                        />
            </div>

            <div className='text-center text-lg font-bold my-10 italic'>
               <h1>KOMMANDER PERSONAL WATERCRAFT (PWC) YOUTH RACING PROGRAM</h1>    
            </div>

             <div className='text-lg text-justify px-5 md:text-xl lg:text-lg  mx-auto lg:px-28 2xl:px-48'>
                <p>The time is NOW to maximize quality family time and create a life time of memories. The Kommander Industries Youth Racing 
                   Program is designed to help families make PWC racing more affordable, and to encourage the youth to have a healthy lifestyle, 
                   learn personal development skills, and make friends amongst the PWC racing community. </p>
            </div>

            <div className='my-4 text-lg px-5 text-justify md:text-xl lg:text-lg mx-auto lg:px-28 2xl:px-48'>
                <p>As a Kommander Youth Racer, you will receive significant discounts on all Kommander race parts and Yamaha Genuine PWC parts 
                   and accessories, shipped to your doorstep. You may also have the opportunity to be featured in Kommander Industries social 
                   media platforms. Make friends, get healthy, have fun and save money! </p>
            </div>

            <div className='mt-8 px-5 text-lg md:text-lg lg:text-xl  font-bold underline underline-offset-2 mx-auto lg:pl-28 2xl:pl-48'>
                <p>Kommander Youth Racing Program Requirements  </p>
            </div>

            <div className='mt-4 text-base px-5 pl-10 md:text-xl lg:text-lg md:pl-16 md:pr-[5rem] lg:pr-[12rem] pb-5 lg:pl-28 2xl:px-48'>
               
                <ul className='list-decimal '>
                    <li>Please email the following to <a href='#/' className='text-red-700'>YouthRacing@kommanderind.com</a></li>
                      <ul className='list-disc pl-10 mt-2 text-justify'>
                        <li className='indent-2 py-2'>A short video (45 seconds maximum) introducing yourself (name, city/state, favorite place to ride your Yamaha PWC, and a fun fact about you). </li>
                        <li className='indent-2'>If available, share a picture of you riding. </li>
                        <li className='indent-2 py-2'>The spelling of your first and last name. </li>
                        <li className='indent-2'>Social media handles.  </li>
                        <li className='indent-2 py-2'>Parent/Legal Guardian’s full name, shipping address, email and consent to share Youth Racer’s video/photo content on social media platforms.  </li>
                      </ul>  
                   <li className='text-justify'>Run a Kommander Youth Racing logo on youth’s Yamaha PWC. Stickers will be provided by Kommander.</li>
                    <div className='flex flex-col justify-items-center items-center mt-16 gap-4 mb-14 lg:ml-20'>
                          <img 
                              src='https://res.cloudinary.com/doehyebmw/image/upload/v1699854028/Kommander/testimonies/testimonies_image_one-min_tz76j3.jpg' 
                              alt='image_one'
                              className='h-[20rem] w-full md:h-[40rem] lg:h-[50rem]'/>
                          <img 
                              src='https://res.cloudinary.com/doehyebmw/image/upload/v1699854030/Kommander/testimonies/testimonies_image_two-min_gxaoqk.jpg' 
                              alt='image_two'
                              className='h-[20rem] w-full  md:h-[40rem] lg:h-[50rem]'/>
                    </div>
                </ul>
          </div>
          </div> 

          
    </div>
    </div>
  )
}

export default InventorySearch;


