import React from 'react'
import Nav from './Nav'
import Footer from './Footer'

const AllInventory = () => {
  const inventoryData = [
     {
          id: 1,
          imageUrl: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
          title: '2024 TRITON TRAILERS LTWCI',
          category: 'New Trailers',
          price: '1,421',
          stockNumber: 'TRIL97987',
          color: 'Siler',
          vin: '4TCSM1111RHL97987'
     },
     
  ];

  return (
    <>
      <Nav />
      <div className='container mx-auto pl-10 md:mt-28 md:pl-10 lg:pl-20 xl:pl-20 md:inline-block '>
          <div className=' text-sm flex items-center mt-4  gap-2'>
                <a href='/'>
                <h3 className='text-red-700'>Home</h3>
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="5.5" stroke="currentColor" 
                className="w-3 h-3 text-gray-800">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
                <a href='/manufacturer_models'>
                <h3 className='text-red-700'>Showroom</h3>
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="5.5" stroke="currentColor" 
                className="w-3 h-3 text-gray-800">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>

                <h3 className='text-gray-500'>All Inventory</h3>
          </div>

            <div className='mt-8 text-lg font-bold'>
                <h1>ALL IN-STOCK INVENTORY</h1>
            </div>
            <div className='mt-5'>
                 <div className='border mr-8 p-5 text-black border-blue-300 bg-gray-200 rounded-md'>
                    <h2 className='font-bold'>SEARCH INVENTORY</h2>
                     <div className='flex gap-5'>
                         <div className='grid-rows-2'>
                              <div className='mt-2 border bg-white rounded-md text-xs w-56  p-2 flex justify-between cursor-pointer'>
                                   <input type='text'  placeholder='All Conditions' />
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                             fill="none" 
                                             viewBox="0 0 24 24" 
                                             strokeWidth="5.5" 
                                             stroke="currentColor" 
                                             className="w-5 h-5 pr-2 ">
                                        <path strokeLinecap="round" 
                                             strokeLinejoin="round" 
                                             d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                              </div>
                              <div className='mt-2 border bg-white rounded-md text-sm w-56  p-2 flex justify-between cursor-pointer'>
                                   <input type='text' placeholder='All Categories' />
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                             fill="none" 
                                             viewBox="0 0 24 24" 
                                             strokeWidth="5.5" 
                                             stroke="currentColor" 
                                             className="w-5 h-5 pr-2 ">
                                        <path strokeLinecap="round" 
                                             strokeLinejoin="round" 
                                             d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                              </div>        
                         </div> 
                         <div className='grid-rows-2'>
                              <div className='mt-2 border bg-white rounded-md text-xs w-56  p-2 flex justify-between cursor-pointer'>
                                   <input type='text'  placeholder='All Manufacturers' />
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                             fill="none" 
                                             viewBox="0 0 24 24" 
                                             strokeWidth="5.5" 
                                             stroke="currentColor" 
                                             className="w-5 h-5 pr-2 ">
                                        <path strokeLinecap="round" 
                                             strokeLinejoin="round" 
                                             d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                              </div>
                              <div className='mt-2 border bg-white rounded-md text-sm w-56  p-2 flex justify-between cursor-pointer'>
                                   <input type='text' placeholder='All Types' />
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                             fill="none" 
                                             viewBox="0 0 24 24" 
                                             strokeWidth="5.5" 
                                             stroke="currentColor" 
                                             className="w-5 h-5 pr-2 ">
                                        <path strokeLinecap="round" 
                                             strokeLinejoin="round" 
                                             d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                              </div>

                         </div>

                         {/* //Years */}
                         <div className='items-start gap-5 flex '> 
                           <div className='grid grid-rows-2 '>    
                            <div className='flex items-center gap-3'>                              
                              <h2 className='text-sm'>Year: </h2>         
                              <div className='mt-2 border bg-white rounded-md text-sm w-24  p-2 flex justify-between cursor-pointer'>
                                  
                                   <input type='text' className='w-10' placeholder='2024' />
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                             fill="none" 
                                             viewBox="0 0 24 24" 
                                             strokeWidth="3.5" 
                                             stroke="currentColor" 
                                             className="w-3 h-5 ">
                                        <path strokeLinecap="round" 
                                             strokeLinejoin="round" 
                                             d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                              </div>

                              <h2 className='text-sm'>To: </h2>         
                              <div className='mt-2 border bg-white rounded-md text-sm w-24  p-2 flex justify-between cursor-pointer'>
                                  
                                   <input type='text' className='w-10' placeholder='2024' />
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                             fill="none" 
                                             viewBox="0 0 24 24" 
                                             strokeWidth="3.5" 
                                             stroke="currentColor" 
                                             className="w-3 h-5 ">
                                        <path strokeLinecap="round" 
                                             strokeLinejoin="round" 
                                             d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                              </div>

                              <h2 className='text-sm'>Price: </h2>         
                              <div className='mt-2 border bg-white rounded-md text-sm w-24  p-2 flex justify-between cursor-pointer'>
                                  
                                   <input type='text' className='w-10' placeholder='2024' />
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                             fill="none" 
                                             viewBox="0 0 24 24" 
                                             strokeWidth="3.5" 
                                             stroke="currentColor" 
                                             className="w-3 h-5 ">
                                        <path strokeLinecap="round" 
                                             strokeLinejoin="round" 
                                             d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                              </div>

                              <h2 className='text-sm'>To: </h2>         
                              <div className='mt-2 border bg-white rounded-md text-sm w-24  p-2 flex justify-between cursor-pointer'>
                                  
                                   <input type='text' className='w-10' placeholder='2024' />
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                             fill="none" 
                                             viewBox="0 0 24 24" 
                                             strokeWidth="3.5" 
                                             stroke="currentColor" 
                                             className="w-3 h-5 ">
                                        <path strokeLinecap="round" 
                                             strokeLinejoin="round" 
                                             d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                              </div>
                           </div>



                           <div className='flex items-center gap-3 content'>                                     
                              <div className='mt-2 border bg-white rounded-md text-sm w-96  p-2 flex justify-between cursor-pointer'>
                                  
                                   <input type='text' className='w-48' placeholder='Model, Stock #, Color, etc.' />        
                              </div>
                              <button className='mt-2 rounded-md py-2 px-8  bg-red-700'>
                                    Clear
                              </button>
                           </div>
                          </div>
                         </div>
                         
                             
                   </div>

                      
                 </div> 
            </div>

            {/* list of items  1  */}
            <div className='my-5'>
                  <div className='flex justify-between mr-16 content-center items-center'>
                         <p className='text-gray-500'>Displaying 1 - 24 of 33</p>
                         <div className='flex gap-5'>
                          <div className='mt-2 border bg-white rounded-md text-sm w-60  p-2 flex justify-between cursor-pointer'>
                                  
                                   <input type='text'  placeholder='Most Relevant' />
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                             fill="none" 
                                             viewBox="0 0 24 24" 
                                             strokeWidth="3.5" 
                                             stroke="currentColor" 
                                             className="w-3 h-5 ">
                                        <path strokeLinecap="round" 
                                             strokeLinejoin="round" 
                                             d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                              </div>
                              <div className='mt-2 border bg-white rounded-md text-sm w-32  p-2 flex justify-between cursor-pointer'>
                                  
                                   <input type='text' className='w-20' placeholder='page 1' />
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                             fill="none" 
                                             viewBox="0 0 24 24" 
                                             strokeWidth="3.5" 
                                             stroke="currentColor" 
                                             className="w-3 h-5 ">
                                        <path strokeLinecap="round" 
                                             strokeLinejoin="round" 
                                             d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                              </div>
                              </div>
                  </div>

                  <div className='mt-16'>
                        <div className='border rounded-sm border-blue-500'>
                         {inventoryData.map((inventory) => (
                           <div className='flex ' key={inventory.id}>   
                              <div className='m-5  border border-blue-500 w-72 h-64 pt-8 rounded-md'>
                                   <img className='object-center bg-cover ' src={inventory.imageUrl} alt=''/>
                              </div>
                              <div className='pt-5'>
                                  <div className='flex justify-between'>    
                                   <a href='#/' >
                                        <h1 className='font-bold hover:text-red-600'>{inventory.title}</h1>
                                        <div className='flex gap-2'>
                                        <h3 className='text-red-700'>{inventory.category}</h3>
                                        <span className='text-gray-400'>Unknown</span>
                                        </div>
                                   </a>

                                        <div className=''>
                                             <h1 className='font-bold text-lg'>${inventory.price}</h1>
                                             <span className='text-gray-400'>Price</span>
                                        </div>
                                   </div>
                                   
                                   <hr className='h-1 font-bold bg-blue-400 my-4'></hr>

                                   <div className='text-gray-800 text-sm grid gap-3 grid-cols-2'>
                                        <div className='flex w-60 justify-between'>
                                             <h3>Stock #: </h3>
                                             <p className='text-gray-500 text-sm'>{inventory.stockNumber}</p>
                                        </div>
                                        <div className='flex w-60 justify-between'>
                                             <h3>Color : </h3>
                                             <p className='text-gray-500 text-sm'>{inventory.color}r</p>
                                        </div>
                                        <div className='flex w-60 justify-between'>
                                             <h3>Vin #: </h3>
                                             <p className='text-gray-500 text-sm'>{inventory.vin}</p>
                                        </div>
                                   </div>
                              </div> 
                           </div>
                           ))}
                           <div className='mx-5 mb-6 flex gap-2'>
                              <div className='flex gap-2 py-3 rounded-md text-white px-6 bg-blue-400 w-72'>
                                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-6 h-6">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                   </svg>
                                   <button className='font-bold text-sm'>REQUEST INFORMATION</button>
                              </div>

                              <div className='flex gap-4 py-3 rounded-md text-white px-6 bg-blue-400 w-72'>
                                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-6 h-6">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                   </svg>
                                   <button className='font-bold text-sm'>REQUEST INFORMATION</button>
                           </div>

                           <div className='flex gap-4 py-3 rounded-md text-white px-6 bg-blue-400 w-72'>
                                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-6 h-6">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                   </svg>
                                   <button className='font-bold text-sm'>REQUEST INFORMATION</button>
                           </div>
                           
                           <div className='flex gap-4 py-3 rounded-md text-white px-6 bg-blue-400 w-72'>
                                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-6 h-6">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                   </svg>
                                   <button className='font-bold text-sm'>REQUEST INFORMATION</button>
                           </div>

                           </div>         
                        </div>      
                  </div>

                        
                       


            </div>
       </div>

       <Footer />
    </>
  )
}

export default AllInventory
