import LandingPage from '../component/landingpage/LandingPage';
import ManufacturerModels from '../component/showroom/ManufacturerModels';
import AllInventory from '../component/showroom/AllInventory';
import NewInventory from '../component/showroom/NewInventory';
import PreOwnedInventory from '../component/showroom/PreOwnedInventory';
import Promotions from '../component/showroom/Promotions';
import Contact from '../component/landingpage/Contact';

const PublicRoutes =[
    {
      path: '/',
      element: <LandingPage />,
    },
    {
      path: '/home',
      element: <LandingPage />,
    },
    // Showroom path
    {
      path: '/showroom/manufacturer_models',
      element: <ManufacturerModels />
    },
    {
      path: '/showroom/all_inventory',
      element: <AllInventory />
    },
    {
      path: '/showroom/new_inventory',
      element: <NewInventory />
    },
    {
      path: '/showroom/preowned',
      element: <PreOwnedInventory />
    },
    {
      path: '/showroom/promotions',
      element: <Promotions />
    },
    {
      path: '/contactus',
      element: <Contact />
    }
    


    
];

export default PublicRoutes;