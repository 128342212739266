import { useState } from "react"
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import { motion } from 'framer-motion'
const products = [
  {
    id: 1,
    name: '2024 YAMAHA SUPERJET',
    color: 'White',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
    imageAlt: 'image1',
    price: '$10,699',
  },
  {
    id: 2,
    name: '2024 YAMAHA FX CRUISER SVHO WITH AUDIO',
    color: 'Black / Acid Green',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagethree_bbxnqn.jpg',
    imageAlt: 'image2',
    price: '$19,799',
  },
  {
    id: 3,
    name: '2024 YAMAHA FX CRUISER HO WITH AUDIOT',
    color: 'Deepwater Blue',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686049/Kommander/Featured_Image/featured_imagefour_r4tdvf.jpg',
    imageAlt: 'image3',
    price: '$16,699',
  },
  {
    id: 4,
    name: '2024 YAMAHA GP SVHO WITH AUDIO',
    color: 'Racing Blue',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686049/Kommander/Featured_Image/featured_imagefive_jedsgy.jpg',
    imageAlt: 'image4',
    price: '$17,599',
  },
  {
    id: 5,
    name: '2024 YAMAHA FX SVHO WITH AUDIO',
    color: 'Black',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686049/Kommander/Featured_Image/featured_image1_udzjmi.jpg',
    imageAlt: 'image1',
    price: '$10,699',
  },
  {
    id: 6,
    name: '2024 YAMAHA SUPERJET',
    color: 'White',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
    imageAlt: 'image1',
    price: '$10,699',
  },
  {
    id: 7,
    name: '2024 YAMAHA SUPERJET',
    color: 'White',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
    imageAlt: 'image1',
    price: '$10,699',
  },
  {
    id: 8,
    name: '2024 YAMAHA SUPERJET',
    color: 'White',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
    imageAlt: 'image1',
    price: '$10,699',
  },
  // {
  //   id: 9,
  //   name: '2024 YAMAHA SUPERJET',
  //   color: 'White',
  //   href: '#/',
  //   imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
  //   imageAlt: 'image1',
  //   price: '$10,699',
  // },
  // {
  //   id: 10,
  //   name: '2024 YAMAHA SUPERJET',
  //   color: 'White',
  //   href: '#/',
  //   imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
  //   imageAlt: 'image1',
  //   price: '$10,699',
  // },
  // {
  //   id: 11,
  //   name: '2024 YAMAHA SUPERJET',
  //   color: 'White',
  //   href: '#/',
  //   imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
  //   imageAlt: 'image1',
  //   price: '$10,699',
  // },
  // {
  //   id: 12,
  //   name: '2024 YAMAHA SUPERJET',
  //   color: 'White',
  //   href: '#/',
  //   imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
  //   imageAlt: 'image1',
  //   price: '$10,699',
  // },

  // More products...
]

const notificationMethods = [
  { id: 'no', title: 'No' },
  { id: 'yes', title: 'Yes' },
  { id: 'maybe', title: 'Maybe' },
]

// Define modal component
const Modal = ({ isOpen, onClose}) => {
  const [ message, setMessage ] = useState('');
  const [error, setError] = useState('');
  const [formValues, setFormValues] = useState({
    firstName: { value: '', hasContent: false },
    lastName: { value: '', hasContent: false },
    phoneNumber: { value: '', hasContent: false },
    email: { value: '', hasContent: false },
  });
  const [success, setSuccess] = useState(false); // Define success state here

  const [tradeInOption, setTradeInOption] = useState('no');



  const handleTradeInChange = (e) => {
    setTradeInOption(e.target.value);
  };

  const form = useRef(null);
 
    // Clear syntax
    const clearForm = () => {
    setFormValues( {
      firstName: {value: '', hasContent: false},
      lastName: {value: '', hasContent: false},
      phoneNumber: {value: '', hasContent: false},
      email: {value: '', hasContent: false},
    });
      setMessage('');
      setError('');
      setFormErrors({
        firstName: false,
        lastName: false,
        phoneNumber: false,
        email: false,
      })
    }
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false,
  });  


    // Sumbit syntax
    const handleSubmit = (e) => {
    e.preventDefault();

    // Createa an object containing the selected trade-in option
    const tradeInData = {
      tradeInOption,
    };
    // Syntax to send the message to my email
     
      // Close syntax to send the message to my email

    const updatedFormValues = { ...formValues};
    Object.keys(updatedFormValues).forEach((key) => {
      updatedFormValues[key].hasContent = !!e.target.elements[key].value.trim();
      updatedFormValues[key].value = e.target.elements[key].value.trim();
    });

    setFormValues(updatedFormValues);


    
    // Basic validation to check if required fields are empty
    const allFieldsFilled = Object.values(updatedFormValues).every(
      (field) => field.hasContent
    );
    if ( allFieldsFilled ) {
      // Process your form submission logic here
      const messageData = {
        ...tradeInData,
        firstName: formValues.firstName.value,
        lastName: formValues.lastName.value,
        phoneNumber: formValues.phoneNumber.value,
        email: formValues.email.value,
        message,
      };
        emailjs.send('service_mmr2cgi', 'template_rpiw6ho', messageData, 'RcSEBGwp0wEWWsF3-')
        .then(
          (result) => {
          console.log('Email with message sent successfully!', result.text);
          clearForm();
          onClose();
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        },
        (error) => {
          console.error('Error sending email with message:', error.text);
        }
      );
    } else {
     setError('Please fill in all required fields.');

     // Update form errors for each field that is empty
     const updateFormErrors = { ...formErrors };
     Object.keys(formValues).forEach((key) => {
      updateFormErrors[key] = !formValues[key].hasContent;
     });
     setFormErrors(updatedFormValues);
      
    }

       
};


    const handleInputChange = (e) => {
      const {id, value} = e.target;
      setFormValues((prevValues) => ({
        ...prevValues,
        [id]: { ...prevValues[id], value: value.trim() },
      }));

      // Clear the form error when the input field is edited
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [id]: false,

      }));
   

    };

    

  return (
   <> 
     {success && (
              <motion.div className="fixed top-1/2 left-1/2 bg-green-500 text-white px-6 py-4 rounded-md shadow-lg max-w-2xl z-50"
                  initial= {{ y: -200 }}
                  animate = {{ y: 0 }}
                  transition={{type: 'spring', duration: 1.5 }}
                  >
                  <div className="text-white">
                    <p className="text-center">Message sent successfully!</p>
                  </div>
                </motion.div>
              )}
      {isOpen && ( 
        <div className="fixed inset-0 flex items-center justify-center content-center bg-black bg-opacity-50 overflow-auto ">
          <div className="bg-white p-6 rounded-lg mt-96 mb-20 md:mt-52 md:w-[40rem]  w-80 overflow-hidden">
            <h2 className="text-lg font-bold text-black"> Contact Information </h2>
              
            {error && 
             
            <div className="text-red-500 text-base font-bold flex gap-2 justify-center">
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24"
                    strokeWidth="2.5" 
                    stroke="currentColor" 
                    className="text-red-500 w-6 h-6">
                <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>
                {error}
            </div>}
            <hr className="bg-gray-200 h-1 rounded-lg"></hr>
            <form onSubmit={handleSubmit} ref={form}>
              <input 
                    id="firstName"
                    type="text"
                    className={`p-2 border mt-2 w-full text-gray-500 rounded-md shadow-inner outline-green-500 bg-transparent shadow-gray-300 ${
                                formValues.firstName.hasContent ? '' : formErrors.firstName ? 'outline-red-500' : ''}`}
                    placeholder="First Name"
                    value={formValues.firstName.value}
                    onChange={handleInputChange}
                     />
                {!formValues.firstName.hasContent && formErrors.firstName && (
                <p className="text-red-500 text-sm font-bold ml-2">Please enter your first name.</p>
                )}     
              <input 
                    id="lastName"
                     type="text"
                    className={`p-2 border mt-2 w-full text-gray-500 rounded-md shadow-inner outline-green-500 bg-transparent shadow-gray-300 ${
                                formValues.lastName.hasContent ? '' : formErrors.lastName ? 'outline-red-500' : ''}
                               }`}
                    value={formValues.lastName.value}
                    onChange={handleInputChange}           
                    placeholder="Last Name" />
                {!formValues.lastName.hasContent && formErrors.lastName && (
                <p className="text-red-500 text-sm font-bold ml-2">Please enter your last name.</p>
                )}         
              <input 
                    id="phoneNumber"
                     type="number"
                    minLength="11"
                    maxLength="11"           
                    className={`p-2 border mt-2 w-full text-gray-500 rounded-md shadow-inner outline-green-500 bg-transparent shadow-gray-300 ${
                                formValues.phoneNumber.hasContent ? '' : formErrors.phoneNumber ? 'outline-red-500' : ''}
                                }`}
                    value={formValues.phoneNumber.value}
                    onChange={handleInputChange}            
                    placeholder="(63)+ " />
                 {!formValues.phoneNumber.hasContent && formErrors.phoneNumber && (
                <p className="text-red-500 text-sm font-bold ml-2">Please enter your phone number.</p>
                )}        
              <input
                      id="email"
                     type="email" 
                    className={`p-2 border mt-2 w-full text-gray-500 rounded-md shadow-inner outline-green-500 bg-transparent shadow-gray-300 ${
                                formValues.email.hasContent ? '' : formErrors.email ? 'outline-red-500' : ''}
                                }`}
                    value={formValues.email.value}
                    onChange={handleInputChange}            
                    placeholder="Email: example@yahoo.com" />
                 {!formValues.email.hasContent && formErrors.email && (
                <p className="text-red-500 text-sm font-bold ml-2">Please enter your email.</p>
                )}        

              <div className="mb-2 mt-2">
                  <label className="text-base font-semibold text-gray-900 ">Trade in?</label>
                  <fieldset className="mt-4 ">
                    <legend className="sr-only">Notification method</legend>
                    <div className="sm:flex sm:items-center sm:space-x-10 sm:space-y-0 flex gap-2 content-center justify-start ">
                      {notificationMethods.map((notificationMethod) => (
                        <div key={notificationMethod.id} className="flex items-center  ">
                          <input
                            id={notificationMethod.id}
                            name="notification-method"
                            type="radio"
                            value={notificationMethod.id}
                            defaultChecked={tradeInOption === notificationMethod.id}
                            onChange={handleTradeInChange}
                            className="h-4 w-4 border-gray-300  text-blue-500 focus:ring-indigo-600"
                          />
                          <label htmlFor={notificationMethod.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                            {notificationMethod.title}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
              </div>                       
              <textarea 
                 id="message"
                 value={message}
                 onChange={(e) => setMessage(e.target.value)}
                 className="w-full p-2 border text-gray-500  border-gray-300 rounded-md mb-3 outline-green-500" 
                 rows={4}
                 placeholder="Comments (optional)"
              />

                <fieldset>
                        <legend className="sr-only">Notifications</legend>
                        <div className="space-y-5">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                checked
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="ml-3  text-sm leading-6">
                              <label htmlFor="comments" className="font-medium text-gray-900">
                              Subscribe to Newsletter
                              </label>
                            </div>
                          </div>
                        </div>
                </fieldset>
                <p className="text-gray-400 text-sm my-4">By pressing SUBMIT, you agree that DX1, LLC and Kommander Industries may call/text you at the number provided, for either informational 
                  or marketing purposes, using an automatic telephone dialing system or pre-recorded/artificial voices. Msg/data rates may apply. You don’t 
                  need to consent as a condition of any purchase & you may revoke your consent at any time. You also agree to our <a href="/#" className="underline underline-offset-1 text-blue-500">Privacy Statement</a>  & 
                   <a href="/#"  className="underline underline-offset-1 text-blue-500"> Terms of Use</a>.</p>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                    clearForm();
                  }}
                  className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
                >
                  Cancel  
                </button>
                <button
                  type="submit" 
                  className="px-4 py-2 bg-blue-600 text-white rounded-md">
                  Submit
                </button>
              </div>
            </form>
          </div>
         
        </div>
          
      )}
       </>
  )}
     
export default function Example() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  
  const openModal = (product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setModalOpen(false);
};


  return (
     <div className="lg:flex md:mr-20 2xl:pr-52">
      
      <div className="mx-auto max-w-2xl px-3 py-5 sm:px-6 sm:py-20 lg:max-w-7xl  ">
        <h2 className="text-xl font-bold text-gray-950 ">Featured Inventory  <button className="text-gray-500 hover:text-red-600 cursor-pointer text-base ml-4">VIEW ALL</button></h2>
        
        <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 md:gap-x-10 md:w-[44rem]   sm:gap-x-6 2xl:w-[90rem]">
          {products.map((product) => (
            <div key={product.id} >
              <div className="relative ">
                <div className="relative h-72 w-full overflow-hidden rounded-lg">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="h-full w-fit object-cover object-center  "
                  />
                </div>
                <div className="relative mt-4 pl-2">
                  <h3 className="text-sm font-medium text-gray-600 truncate ">{product.name}</h3>
                  <p className="mt-1 text-sm text-gray-600">{product.color}</p>
                </div>
                <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                  />
                  <p className="relative text-lg font-semibold text-white">{product.price}</p>
                </div>
              </div>
              <div className="mt-6">
                <button
                  onClick={() => openModal(product)}
                  className="relative flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-red-600 gap-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                  </svg>
                  Contact Us
                  <span className="sr-only">, {product.name}</span>
                </button>
              </div>
            </div>
          ))}
           {/* Modal component */}
           <Modal  
              isOpen={modalOpen}
              onClose={closeModal}
              productName={selectedProduct ? selectedProduct.name : ''}  
           />
        </div>
      </div>
    </div>
  );
}
   
 
 
  // <div className='mt-10'>
  //                             <h1>Complete the sentence and type your short answer below. I deserve to get accepted because:</h1>
  //                             <input 
  //                                   id='message'
  //                                   type='text'
  //                                   value={formValues.message.value}
  //                                   onChange={handleInputChange}
  //                                   className='border p-2 my-10 text-black rounded-md w-[75rem] outline-green-300' />
  //                         </div> */}