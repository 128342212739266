import React from 'react'
import Nav from '../showroom/Nav'

const Contact = () => {
  return (
    <>
    <Nav />
      <div className=' text-sm flex items-center text-white mt-4  gap-2'>
                <a href='/'>
                <h3 className='text-red-700'>Home</h3>
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="5.5" stroke="currentColor" 
                className="w-4 h-3 text-gray-800">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
                <a href='/manufacturer_models'>
                <h3 className='text-red-700'>Showroom</h3>
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="5.5" stroke="currentColor" 
                className="w-3 h-3 text-gray-800">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>

                <h3 className='text-gray-500'>Manufacturer Models</h3>
          </div>
    </>
  )
}

export default Contact
