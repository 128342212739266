import React from 'react'
import Nav  from './Nav';
import Footer from './Footer';

const Promotions = () => {
  return (
    <>
        <Nav />
              <div className='container mx-auto  md:mt-28 mb-11'>
                  <div className=' text-sm flex items-center  mt-4   gap-2'>
                      <a href='/'>
                      <h3 className='text-red-700'>Home</h3>
                      </a>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" 
                      className="w-3 h-3">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                      </svg>
                      <a href='/manufacturer_models'>
                      <h3 className='text-red-700'>Showroom</h3>
                      </a>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" 
                      className="w-3 h-3">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                      </svg>

                      <h3>Promotions</h3>
                </div>
                <div className='mt-10'>
                      <h1 className='font-bold text-5xl'>MANUFACTURER MODELS</h1>
                    <div className=' p-5 bg-blue-200 bg-opacity-20 text-gray-500 mr-40 mt-12 border border-blue-400 font-light'>
                    <h2>No promotions available at this time</h2>
                </div>
                </div>
              </div>
        <Footer  />
    </>
  )
}

export default Promotions
