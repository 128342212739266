import React from 'react'

const Cards = () => {
   const cards =[
    {
        url:'https://res.cloudinary.com/doehyebmw/image/upload/v1700640707/Kommander/Cards/Cards1_x6agwz.jpg',   
        desc1:'SHOP',
        desc2:'NEW INVENTORY',   
    },
    {
        url:'https://res.cloudinary.com/doehyebmw/image/upload/v1700641865/Kommander/Cards/Cards2_c8xnps.jpg',   
        desc1:'FIND',
        desc2:'PARTS',   
    },
    {
        url:'https://res.cloudinary.com/doehyebmw/image/upload/v1700640707/Kommander/Cards/Cards3_ks8zjb.jpg',   
        desc1:'SHOP',
        desc2:'GP1',   
    },
   ];


  return ( 
  
          <div 
                    className='flex flex-col my-5 px-5  justifyt-center gap-5 sm:flex-row sm:px-5 container mx-auto'
                   >
            {cards.map((card, index) =>(
            <div 
                key={index} 
                className='flex items-center justify-center'
                
            >

                <img 
                    className='relative h-64 rounded  sm:w-screen md:w-72 lg:w-[30rem] lg:h-[20rem] xl:w-[26rem] xl:h-[26rem] xl:pl-4 2xl:w-[50rem] '
                    src={card.url}
                    alt='new Inventory'/>
                    <div className='absolute text-white text-center flex flex-col '>
                        <span className='font-bold drop-shadow-xl text-3xl '>{card.desc1}</span>
                        <span className='font-light drop-shadow-xl text-4xl sm:text-2xl '>{card.desc2}</span>
                    </div>
            </div>  
))}
           
          </div>  

  )
}

export default Cards


 