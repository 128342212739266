import React from 'react';
import {BsChevronCompactLeft} from 'react-icons/bs';
import {BsChevronCompactRight} from 'react-icons/bs';
import { useState, useEffect } from 'react';

const Carousel = () => {
const slides = [
{
url : 'https://res.cloudinary.com/doehyebmw/image/upload/v1699511441/Kommander/Carousel/carousel-1_wk1xr3.jpg',
},
{
url : 'https://res.cloudinary.com/doehyebmw/image/upload/v1699576527/Kommander/Carousel/Carousel_two-min_rk39sn.jpg',
},
{
url : 'https://res.cloudinary.com/doehyebmw/image/upload/v1699576518/Kommander/Carousel/Carousel_four-min_r17nwk.jpg',
},
{
url : 'https://res.cloudinary.com/doehyebmw/image/upload/v1699576482/Kommander/Carousel/Carousel_three-min_nxjg9a.jpg',
},
];

const [currentIndex, setCurrentIndex ] = useState(0);

const prevSlide = () => {
const isFirstSlide = currentIndex === 0;
const newIndex = isFirstSlide ? slides.length - 1 : currentIndex -1;
setCurrentIndex(newIndex);
};

const nextSlide = () => {
const isLastSlide = currentIndex === slides.length - 1;
const newIndex = isLastSlide ? 0 : currentIndex + 1;
setCurrentIndex (newIndex);
};

// const goToSlide = (slideIndex) => {
// setCurrentIndex(slideIndex);
// };

useEffect(() => {
const interval = setInterval (() => {
// Increase the current slide index
setCurrentIndex((prevSlide) => (prevSlide + 1 ) % totalSlides);
}, 5000); // Change the interval as needed ( in milliseconds)

//Cleanup interval on component unmount
return () => clearInterval(interval);
}, [currentIndex]);

const totalSlides = 4; // Adjust this based on the number of slides naa nato;

// const settings = {
// dots: true,
// infinite: true,
// speed: 500,
// slidesToShow: 1,
// slidesToScroll: 1,
// autoplay: false, // Disable autoplay since we are handling it manually
// beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
// };

return (
<>

    <div className=' h-64 md:h-80 lg:h-[40rem] xl:h-[40em] 2xl:h-[50rem] flex relative mt-4 md:mt-0  '>

        <img className='w-full h-full transition-transform ease-out bg-center bg-cover bg-fixed duration-500'
            src={slides[currentIndex].url} alt='' />
        <div className='xl:content-center'>
            {/* left arrow */}
            <div className='absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 md:left-16 lg:left-20 xl:left-24 text-2xl 
                      2xl:left-44      rounded-full p-2 
                    bg-black/20 text-white transition duration-150 hover:bg-blue-400  cursor-pointer'>
                <BsChevronCompactLeft className='ease-out duration-150' size={30} onClick={prevSlide} />
            </div>

            {/* right arrow */}
            <div className='absolute top-[50%] translate-x-0 -translate-y-[50%] right-5 md:right-16 lg:right-28 xl:right-52 text-2xl 2xl:right-[15rem]
                      rounded-full p-2 bg-black/20 text-white 
                       hover:bg-sky-500 ease-out duration-150 cursor-pointer'>
                <BsChevronCompactRight size={30} onClick={nextSlide} />
            </div>
        </div>
    </div>

</>
)
}

export default Carousel